import React, { useState, useEffect, useContext, Suspense } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';

import { routesPath } from 'routes';
import SideNav from './SideNav';
import SideNavSubMenu from './SideNavSubMenu';
import sideMenuItem from './SideMenuItem';
import Header from 'components/Header/LLHeader';
import Snackbar from 'components/Snackbar/LLSnackbar';
import useBreakPoint from 'hooks/useBreakPoint';
import handleGetPageTitleOnPageLoad from 'utils/handleGetPageTitle';
import { DrawerContext } from 'context/useDrawerContext';
import { NotificationContext } from 'context/useNotificationContext';

import MenuIcon from 'assets/Icons/MenuIcon';
import logo from 'assets/Logo/360Live-horizental.svg';
import LoadingIndicator from '../../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    overflow: 'hidden',
    background: 'white',
  },
  drawer: {
    width: 220,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 220,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.dark,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '73px',
    backgroundColor: theme.palette.background.dark,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    '& img': {
      maxHeight: 40,
      marginLeft: 5,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    paddingLeft: '20px',
  },
  subMenuWrapper: {
    width: '200px',
    paddingTop: '56px',
    borderRight: 'solid 1px rgba(9, 28, 43, 0.08)',
    height: '100vh',
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      width: '150px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '182px',
    },
  },
  menuIconButton: {
    paddingLeft: '20px',
    paddingRight: '27px',
  },
  content: {
    background: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function LLAdminLayout({ content: Content }) {
  const classes = useStyles();
  const location = useLocation();
  const { deviceWidth } = useBreakPoint();
  const { isDrawerOpen, setIsDrawerOpen } = useContext(DrawerContext);
  const [pageTitle, setPageTitle] = useState(handleGetPageTitleOnPageLoad());
  const { snackbarState, setSnackbarState } = useContext(NotificationContext);

  const [hasSubMenu, setHasSubMenu] = useState(false);
  const [subMenuItem, setSubMenuItem] = useState();
  const [intSub, setIntSub] = useState(null);
  const [previousDrawer, setPreviousDrawer] = useState(isDrawerOpen);

  useEffect(() => {
    sideMenuItem.forEach((item) => {
      if (item.href === location.pathname || item.href?.split('/')[1] === location.pathname?.split('/')[1]) {
        setIntSub(item.subMenu);
        if (item.subMenu.length >= 1) {
          setHasSubMenu(true);
        } else {
          setHasSubMenu(false);
        }
      }
    });
  }, [hasSubMenu, location.pathname]);

  useEffect(() => {
    if (deviceWidth < 1080) {
      setIsDrawerOpen(false);
    } else {
      previousDrawer && setIsDrawerOpen(true);
    }
  }, [deviceWidth, setIsDrawerOpen, previousDrawer]);

  const handleOnMenuClick = (subMenu) => {
    if (subMenu.length >= 1) {
      setHasSubMenu(true);
      setSubMenuItem(subMenu);
    } else {
      setSubMenuItem(null);
      setHasSubMenu(false);
    }
  };

  const handleOnDrawerClick = () => {
    const value = !isDrawerOpen;
    setPreviousDrawer(value);
    setIsDrawerOpen(value);
  };

  return (
    <>
      <Snackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
      <Box display="flex" className={classes.mainBox}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isDrawerOpen,
              [classes.drawerClose]: !isDrawerOpen,
            }),
          }}
        >
          <div className={classes.logo}>
            <Box disableRipple component={IconButton} className={classes.menuIconButton} onClick={handleOnDrawerClick}>
              <MenuIcon />
            </Box>
            <img src={logo} alt="360Live logo" />
          </div>
          <SideNav isDrawerOpen={isDrawerOpen} setPageTitle={setPageTitle} handleClick={handleOnMenuClick} />
        </Drawer>
        {hasSubMenu && location.pathname !== routesPath.updatePassword && (
          <div className={classes.subMenuWrapper}>
            <SideNavSubMenu subMenuItem={subMenuItem || intSub} />
          </div>
        )}
        <Header open={isDrawerOpen} pageTitle={pageTitle} setPageTitle={setPageTitle} setHasSubMenu={setHasSubMenu} />
        <Box
          component="main"
          className={classes.content}
          style={{
            width: `calc(100% - ${isDrawerOpen ? '220px' : '73px'} - ${hasSubMenu ? '170px' : '0px'}`,
          }}
          display={hasSubMenu ? 'flex' : 'block'}
          marginLeft={hasSubMenu ? '20px' : '0px'}
        >
          <Box width="100%">
            <div className={classes.toolbar} />
            <Suspense fallback={<LoadingIndicator height="100vh" />}>
              <Content />
            </Suspense>
          </Box>
        </Box>
      </Box>
    </>
  );
}
