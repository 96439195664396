import React from 'react';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import Link from 'components/Link/LLLink';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
    width: '220px',
    background: 'white',
    [theme.breakpoints.down('md')]: {
      width: '170px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  content: {
    minHeight: '100vh',
    borderLeft: ' rgba(9, 28, 43, 0.08) solid 1px',
    position: 'fixed',
    zIndex: 11,
    background: 'white',
    width: '220px',
    [theme.breakpoints.down('md')]: {
      width: '170px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  linkWrapper: {
    width: '100%',
    color: 'black',
    textDecoration: 'none',
  },
  linkLabel: {
    textDecoration: 'none',
    zIndex: 5,
    margin: '20px 0',
  },
  activeSubNav: {
    background: theme.palette.background.greenLight,
    zIndex: 4,
    color: 'black',
    '&:hover': {
      background: theme.palette.background.greenLight,
    },
  },
  hoverSubNav: {
    '&:hover': {
      background: theme.palette.background.greenLight,
    },
  },
  activeNavBorderLeft: {
    position: 'absolute',
    '&:before': {
      content: "''",
      position: 'absolute',
      borderLeft: `3px solid ${theme.palette.background.green}`,
      right: 0,
      left: '200px',
      bottom: -26,
      height: '53px',
      [theme.breakpoints.down('md')]: {
        left: '150px',
      },
      [theme.breakpoints.up('lg')]: {
        left: '180px',
      },
    },
  },
}));

export default function SideNavSubMenu({ subMenuItem }) {
  const classes = useStyles();
  const location = useLocation();

  const activeSubLink = (route) => (route === location.pathname ? true : false);

  return (
    <List className={classes.root}>
      <div className={classes.content}>
        {subMenuItem.map((item, index) => (
          <Link key={index} to={item.href} classNames={clsx(classes.linkLabel)}>
            <ListItem
              key={index}
              button
              disableRipple
              className={clsx(
                activeSubLink(item.href) ? classes.activeSubNav : classes.hoverSubNav,
                classes.linkWrapper
              )}
            >
              {activeSubLink(item.href) && <div className={classes.activeNavBorderLeft}> </div>}
              <ListItemText disableTypography primary={item.label} />
            </ListItem>
          </Link>
        ))}
      </div>
    </List>
  );
}
