import React from 'react';
import clsx from 'clsx';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import ErrorIcon from 'assets/Icons/ErrorIcon';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((_theme) => ({
  root: {
    width: '320px',
    minHeight: '48px',
    padding: '12px 6px',
    color: '#000',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  errorAlertBGColor: {
    backgroundColor: '#FEF1F1',
    borderRadius: 8,
  },
  successAlertBGColor: {
    backgroundColor: '#cdfbcd',
  },
}));

export default function LLAlert({ type, children }) {
  const classes = useStyles();
  return (
    <>
      {type === 'error' && (
        <div className={clsx(classes.root, classes.errorAlertBGColor)}>
          <Box component="span" marginX={1}>
            <ErrorIcon />
          </Box>
          <Typography variant="body2">{children}</Typography>
        </div>
      )}
      {type === 'success' && (
        <div className={clsx(classes.root, classes.successAlertBGColor)}>
          <Box component="span" marginX={1}>
            <CheckCircleOutlineIcon color="primary" />
          </Box>
          <Typography variant="body2">{children}</Typography>
        </div>
      )}
    </>
  );
}
