import { createTheme } from '@material-ui/core/styles';
import Colors from 'Color';

export default createTheme({
  palette: {
    primary: {
      main: '#0088ED',
      mainLight: '#30B9EF',
      mainDark: 'rgba(0, 136, 237, 0.08)',
      dark: '#091c2b',
      darkLight: '#001928',
      white: '#fff',
    },
    success: {
      main: Colors.green,
      light: '#45d359',
    },
    danger: {
      main: '#EB5757',
      light: '#d16060',
      dark: '#f04343',
      error: '#f44336',
    },
    interpreterStatusColor: {
      available: '#27AE60',
      busy: '#EB5757',
      away: '#C5C5C5',
    },
    background: {
      white: '#fff',
      light: '#e5e5e5',
      dark: '#091c2b',
      darkLight: '#001928',
      darkLighter: '#344454',
      whiteSmoke: '#F5F5F5',
      turquoise: Colors.turquoise,
      green: Colors.green,
      greenLight: 'rgb(36,215,76,0.2)',
    },
    shadow: {
      main: '0px 4px 8px rgba(9, 28, 43, 0.08)',
    },
    action: {
      disabled: {
        color: '#ffffff',
      },
    },
  },
  typography: {
    fontFamily: 'campton-book',
    button: {
      textTransform: 'none',
    },
  },
  brand: '#E22525',
  overrides: {
    MuiTypography: {
      caption: {
        color: 'rgba(9, 28, 43, 0.5)',
        fontSize: '13px',
        fontWeight: '500',
      },
      body1: {
        fontFamily: 'campton-medium',
      },
      body2: {
        fontFamily: 'campton-medium',
      },
    },
    MuiButton: {},
    MuiDialog: {
      paperWidthXs: {
        maxWidth: '480px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px 0 24px',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '13px',
        paddingBottom: '13px',
      },
      button: {
        '&:hover': {
          background: 'transparent',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      elevation8: {
        border: '1px solid rgba(0, 136, 237, 0.08)',
        boxShadow: '0px 2px 6px rgba(9, 28, 43, 0.15)',
        borderRadius: '8px',
      },
    },
    MuiPickersDay: {
      root: {
        color: Colors.green,
        '&$selected': {
          backgroundColor: Colors.green,
        },
      },
      daySelected: {
        backgroundColor: Colors.green,
      },
      current: {
        color: Colors.green,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: 'rgb(36, 215, 76, 0.2)',
      },
      barColorPrimary: {
        backgroundColor: Colors.green,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: Colors.green,
      },
    },
    MuiInputBase: {
      focused: {
        border: 'green',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover fieldset': {
          borderColor: `${Colors.primary} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${Colors.primary} !important`,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: `${Colors.primary} !important`,
        },
      },
    },
  },
});
