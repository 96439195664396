import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Alert from '../../components/Alert/LLAlert';
import Input, { emailRegex } from '../../components/Input/Input';
import Password from '../../components/Input/Password';
import { PrimaryButton } from '../../components/Buttons';
import LoginPageLayout from '../../components/Layout/LoginPageLayout';
import { useAppState } from '../../state';
import AuthErrorResolver from '../../resolvers/authErrorResolver';
import Colors from 'Color';

const useStyles = makeStyles((_) => ({
  input: {
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      background: 'none',
      color: 'white',
      fontFamily: 'campton-medium',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
  },
  signInButton: {
    background: Colors.primary,
    color: Colors.white,
    fontFamily: 'campton-medium',
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      background: Colors.primary,
      opacity: 0.8,
    },
  },
}));

export default function SignInForm() {
  const { control, handleSubmit } = useForm();
  const [authError, setAuthError] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const { signIn } = useAppState();
  const history = useHistory();
  const classes = useStyles();

  const handleSignIn = async ({ email, password }) => {
    setIsLoading(true);
    try {
      await signIn(email, password);
      history.replace(location?.state?.from || { pathname: '/' });
    } catch (error) {
      const message = AuthErrorResolver.resolve(error);
      setAuthError({ ...error, message });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <LoginPageLayout title="Sign in">
      <form onSubmit={handleSubmit(handleSignIn)}>
        <Grid container className={classes.input}>
          <Box item component={Grid} paddingY={1} xs={12}>
            {authError && (
              <Box mb={2}>
                <Alert type="error" style={{ width: '100%' }}>
                  {authError.message}
                </Alert>
              </Box>
            )}
            <Controller
              name="email"
              type="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error }, ref }) => (
                <Input
                  fullWidth
                  variant="outlined"
                  value={value}
                  error={!!error}
                  control={control}
                  placeholder="Email address"
                  helperText={error ? error.message : null}
                  inputRef={ref}
                  type="email"
                  onChange={onChange}
                />
              )}
              rules={{
                required: <Typography variant="body1">This field is required</Typography>,
                pattern: emailRegex,
              }}
            />
          </Box>

          <Box item component={Grid} paddingTop={1} paddingBottom={2} xs={12}>
            <Controller
              name="password"
              type="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error }, ref }) => (
                <Password
                  fullWidth
                  variant="outlined"
                  value={value}
                  error={!!error}
                  control={control}
                  placeholder="Password"
                  helperText={error ? error.message : null}
                  inputRef={ref}
                  type="password"
                  onChange={onChange}
                />
              )}
              rules={{ required: <Typography variant="body1">This field is required</Typography> }}
            />
          </Box>

          <Grid item xs={12}>
            <PrimaryButton
              fullWidth
              className={classes.signInButton}
              variant="contained"
              type="submit"
              loading={loading}
            >
              Sign in
            </PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </LoginPageLayout>
  );
}
