const AuthErrorResolver = {
  resolve: ({ code, message }) => {
    switch (code) {
      case 'auth/user-not-found':
      case 'auth/invalid-user-type':
        return 'This is no user associate with this account.';
      case 'auth/user-disabled':
        return 'This user has been disabled.';
      case 'auth/wrong-password':
        return 'Incorrect email or password';
    }

    return message;
  },
};

export default AuthErrorResolver;
