import React from 'react';
import clsx from 'clsx';
import { Box, Typography, makeStyles } from '@material-ui/core';
import bgImage from 'assets/bg.svg';
import logo from 'assets/Logo/360Live-192.png';
import Colors from 'Color';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: `${Colors.background.dark}`,
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '230%',
    '& .MuiInputBase': {
      root: {
        borderRadius: '8px',
        background: 'none',
        color: 'white',
        fontFamily: 'campton-medium',
      },
    },
    '& .MuiOutlinedInput': {
      root: { border: 'none' },
      notchedOutline: {
        border: 'none',
        background: 'rgba(255, 255, 255, 0.05)',
      },
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    textAlign: 'center',
    maxHeight: 126,
  },
  paper: {
    height: 'auto',
    width: '390px',
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.main,
    background: 'rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
    color: theme.palette.success.main,
  },
}));

export default function LoginPageLayout({ title, children, footer }) {
  const classes = useStyles();
  return (
    <section className={clsx(classes.root, classes.center)}>
      <Box m={2}>
        <img src={logo} alt="360Live logo" className={classes.logo} />
      </Box>
      <div className={clsx(classes.paper, classes.center)}>
        <Box mb={2} display="flex" alignItems="center">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        {children}
      </div>
      {footer}
    </section>
  );
}
